// if (typeof varExists !== 'function') {
//     window.varExists = function (varName) {
//         return new Promise(function (resolve, reject) {
//             (function waitForVar() {
//                 if (typeof window[varName] !== 'undefined') return resolve();
//                 setTimeout(waitForVar, 30);
//             })();
//         });
//     }
// }

// Promise.allSettled(
//     [
//         varExists('jQuery'),
//         varExists('wpm')
//     ]).then(function () {
// // do something
//
// }).catch(function (e) {
//     console.log(e);
// })


(function (wpm, $, undefined) {

    const wpmDeduper = {
        keyName          : '_wpm_order_ids',
        cookieExpiresDays: 365
    };

    const wpmRestSettings = {
        // cookiesAvailable                  : '_wpm_cookies_are_available',
        cookieWpmRestEndpointAvailable: '_wpm_endpoint_available',
        restEndpoint                  : '/wp-json/',
        restFails                     : 0,
        restFailsThreshold            : 10,
    }


    // wpm.checkIfCookiesAvailable = function () {
    //
    //     // read the cookie if previously set, if it is return true, otherwise continue
    //     if (wpm.getCookie(wpmRestSettings.cookiesAvailable)) {
    //         return true;
    //     }
    //
    //     // set the cookie for the session
    //     Cookies.set(wpmRestSettings.cookiesAvailable, true);
    //
    //     // read cookie, true if ok, false if not ok
    //     return !!wpm.getCookie(wpmRestSettings.cookiesAvailable);
    // }

    wpm.useRestEndpoint = function () {

        // only if sessionStorage is available

        // only if REST API endpoint is generally accessible
        // check in sessionStorage if we checked before and return answer
        // otherwise check if the endpoint is available, save answer in sessionStorage and return answer

        // only if not too many REST API errors happened

        return wpm.isSessionStorageAvailable() &&
            wpm.isRestEndpointAvailable() &&
            wpm.isBelowRestErrorThreshold();
    }

    wpm.isBelowRestErrorThreshold = function () {
        return window.sessionStorage.getItem(wpmRestSettings.restFails) <= wpmRestSettings.restFailsThreshold;
    }

    wpm.isRestEndpointAvailable = function () {

        if (window.sessionStorage.getItem(wpmRestSettings.cookieWpmRestEndpointAvailable)) {
            return JSON.parse(window.sessionStorage.getItem(wpmRestSettings.cookieWpmRestEndpointAvailable));
        } else {
            // return wpm.testEndpoint();
            // just set the value whenever possible in order not to wait or block the main thread
            wpm.testEndpoint();
        }
    }

    wpm.isSessionStorageAvailable = function () {

        return !!window.sessionStorage;
    }

    wpm.testEndpoint = function (
        url        = location.protocol + '//' + location.host + wpmRestSettings.restEndpoint,
        cookieName = wpmRestSettings.cookieWpmRestEndpointAvailable
    ) {
        // console.log('testing endpoint');

        jQuery.ajax(url, {
            type   : 'HEAD',
            timeout: 1000,
            // async: false,
            statusCode: {
                200: function (response) {
                    // Cookies.set(cookieName, true);
                    // console.log('endpoint works');
                    window.sessionStorage.setItem(cookieName, JSON.stringify(true));
                },
                404: function (response) {
                    // Cookies.set(cookieName, false);
                    // console.log('endpoint doesn\'t work');
                    window.sessionStorage.setItem(cookieName, JSON.stringify(false));
                },
                0  : function (response) {
                    // Cookies.set(cookieName, false);
                    // console.log('endpoint doesn\'t work');
                    window.sessionStorage.setItem(cookieName, JSON.stringify(false));
                }
            }
        }).then(r => {
            // console.log('test done')
            // console.log('result: ' + JSON.parse(window.sessionStorage.getItem(cookieName)));
            // return JSON.parse(window.sessionStorage.getItem(cookieName));
        });
    }

    wpm.isWpmRestEndpointAvailable = function (cookieName = wpmRestSettings.cookieWpmRestEndpointAvailable) {

        return !!wpm.getCookie(cookieName);
    }

    wpm.objectExists = function (obj) {

        for (let i = 1; i < arguments.length; i++) {
            if (!obj.hasOwnProperty(arguments[i])) {
                return false;
            }
            obj = obj[arguments[i]];
        }
        return true;
    }

    wpm.writeOrderIdToStorage = function (orderId, expireDays = 365) {

        // save the order ID in the browser storage

        if (!window.Storage) {
            let expiresDate = new Date();
            expiresDate.setDate(expiresDate.getDate() + wpmDeduper.cookieExpiresDays);

            let ids = [];
            if (checkCookie()) {
                ids = JSON.parse(wpm.getCookie(wpmDeduper.keyName));
            }

            if (!ids.includes(orderId)) {
                ids.push(orderId);
                document.cookie = wpmDeduper.keyName + '=' + JSON.stringify(ids) + ';expires=' + expiresDate.toUTCString();
            }

        } else {
            if (localStorage.getItem(wpmDeduper.keyName) === null) {
                let ids = [];
                ids.push(orderId);
                window.localStorage.setItem(wpmDeduper.keyName, JSON.stringify(ids));

            } else {
                let ids = JSON.parse(localStorage.getItem(wpmDeduper.keyName));
                if (!ids.includes(orderId)) {
                    ids.push(orderId);
                    window.localStorage.setItem(wpmDeduper.keyName, JSON.stringify(ids));
                }
            }
        }

        if (typeof wpm.storeOrderIdOnServer === 'function' && wpmDataLayer.orderDeduplication) {
            wpm.storeOrderIdOnServer(orderId);
        }
    }

    function checkCookie() {
        let key = wpm.getCookie(wpmDeduper.keyName);
        return key !== '';
    }

    wpm.isOrderIdStored = function (orderId) {

        // console.log('deduper: ' + wpmDataLayer.orderDeduplication);
        if (wpmDataLayer.orderDeduplication) {
            // console.log('order deduplication: on');
            if (!window.Storage) {

                if (checkCookie()) {
                    let ids = JSON.parse(wpm.getCookie(wpmDeduper.keyName));
                    return ids.includes(orderId);
                } else {
                    return false;
                }
            } else {
                if (localStorage.getItem(wpmDeduper.keyName) !== null) {
                    let ids = JSON.parse(localStorage.getItem(wpmDeduper.keyName));
                    return ids.includes(orderId);
                } else {
                    return false;
                }
            }
        } else {
            console.log('order deduplication: off');
            return false;
        }
    }

    wpm.isEmail = function (email) {
        // https://emailregex.com/
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    wpm.removeProductFromCart = function (productId, quantityToRemove = null) {
        // console.log('product_id: ' + productId + ' | quantityToRemove: ' + quantityToRemove);

        try {

            if (!productId) throw Error('Wasn\'t able to retrieve a productId');

            productId = getIdBasedOndVariationsOutputSetting(productId);

            if (!productId) throw Error('Wasn\'t able to retrieve a productId');

            let quantity;

            if (quantityToRemove == null) {
                quantity = wpmDataLayer.cart[productId].quantity;
            } else {
                quantity = quantityToRemove;
            }

            // console.log('product_id: ' + productId + ' | qty: ' + quantity);
            // console.log(productId);
            // console.log(wpmDataLayer.cart);
            // console.log(wpmDataLayer.cart[productId]);

            if (wpmDataLayer.cart[productId]) {

                // let product = {
                //     id       : productId.toString(),
                //     dyn_r_ids: wpmDataLayer.cart[productId].dyn_r_ids,
                //     name     : wpmDataLayer.cart[productId].name,
                //     // list_name: wpmDataLayer.shop.list_name, // doesn't make sense on mini_cart
                //     brand   : wpmDataLayer.cart[productId].brand,
                //     category: wpmDataLayer.cart[productId].category,
                //     variant : wpmDataLayer.cart[productId].variant,
                //     // list_position: wpmDataLayer.cart[productId].position, // doesn't make sense on mini_cart
                //     quantity   : quantity,
                //     price      : wpmDataLayer.cart[productId].price,
                //     isVariation: wpmDataLayer.products[productId].isVariation,
                // };
                //
                // if (product.isVariation) product['parentId_dyn_r_ids'] = wpmDataLayer.products[productId].parentId_dyn_r_ids;

                let product = wpm.getProductDetailsFormattedForEvent(productId, quantity);

                // console.log('removing');
                // console.log(data);

                jQuery(document).trigger('wpmRemoveFromCart', product);

                if (quantityToRemove == null || wpmDataLayer.cart[productId].quantity === quantityToRemove) {
                    delete wpmDataLayer.cart[productId];
                    if (sessionStorage) sessionStorage.setItem('wpmDataLayerCart', JSON.stringify(wpmDataLayer.cart));
                } else {
                    wpmDataLayer.cart[productId].quantity = wpmDataLayer.cart[productId].quantity - quantity;
                    if (sessionStorage) sessionStorage.setItem('wpmDataLayerCart', JSON.stringify(wpmDataLayer.cart));
                }
            }
        } catch (e) {
            console.log(e);
            // console.log('getting cart from back end');
            // wpm.getCartItemsFromBackend();
            // console.log('getting cart from back end done');

        }
    }

    getIdBasedOndVariationsOutputSetting = function (productId) {

        try {
            if (wpmDataLayer.general.variationsOutput) {
                // console.log('test');
                return productId;
            } else {
                if (wpmDataLayer.products[productId].isVariation) {
                    return wpmDataLayer.products[productId].parentId;
                } else {
                    return productId;
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    // add_to_cart
    wpm.addProductToCart = function (productId, quantity) {

        try {
            // console.log('productId: ' + productId + ' | qty: ' + quantity);
            // console.log('productId: ' + productId + ' | variationId: ' + variationId + ' | qty: ' + quantity);

            if (!productId) throw Error('Wasn\'t able to retrieve a productId');

            productId = getIdBasedOndVariationsOutputSetting(productId);

            if (!productId) throw Error('Wasn\'t able to retrieve a productId');

            if (wpmDataLayer.products[productId]) {

                let product = wpm.getProductDetailsFormattedForEvent(productId, quantity);

                // console.log(productId);

                jQuery(document).trigger('wpmAddToCart', product);

                // add product to cart wpmDataLayer['cart']

                // if the product already exists in the object, only add the additional quantity
                // otherwise create that product object in the wpmDataLayer['cart']
                if (wpmDataLayer.cart !== undefined && wpmDataLayer.cart[productId] !== undefined) {
                    wpmDataLayer.cart[productId].quantity = wpmDataLayer.cart[productId].quantity + quantity;
                    if (sessionStorage) sessionStorage.setItem('wpmDataLayerCart', JSON.stringify(wpmDataLayer.cart));
                } else {

                    if (!wpmDataLayer.cart) {

                        // wpmDataLayer['cart'] = {
                        //     [productId]: {
                        //         id         : productId,
                        //         dyn_r_ids  : wpmDataLayer.products[productId].dyn_r_ids,
                        //         name       : wpmDataLayer.products[productId].name,
                        //         brand      : wpmDataLayer.products[productId].brand,
                        //         category   : wpmDataLayer.products[productId].category,
                        //         variant    : wpmDataLayer.products[productId].variant,
                        //         quantity   : quantity,
                        //         price      : wpmDataLayer.products[productId].price,
                        //         isVariation: wpmDataLayer.products[productId].isVariation,
                        //         parentId   : wpmDataLayer.products[productId].parentId,
                        //     }
                        // };
                        //
                        // if (wpmDataLayer['cart'][productId].isVariation) wpmDataLayer['cart'][productId]['parentId_dyn_r_ids'] = wpmDataLayer.products[productId].parentId_dyn_r_ids;

                        wpmDataLayer['cart'] = {
                            [productId]: wpm.getProductDetailsFormattedForEvent(productId, quantity)
                        };

                        if (sessionStorage) sessionStorage.setItem('wpmDataLayerCart', JSON.stringify(wpmDataLayer.cart));
                    } else {

                        // wpmDataLayer.cart[productId] = {
                        //     id         : productId,
                        //     dyn_r_ids  : wpmDataLayer.products[productId].dyn_r_ids,
                        //     name       : wpmDataLayer.products[productId].name,
                        //     brand      : wpmDataLayer.products[productId].brand,
                        //     category   : wpmDataLayer.products[productId].category,
                        //     variant    : wpmDataLayer.products[productId].variant,
                        //     quantity   : quantity,
                        //     price      : wpmDataLayer.products[productId].price,
                        //     isVariation: wpmDataLayer.products[productId].isVariation,
                        //     parentId   : wpmDataLayer.products[productId].parentId,
                        // };
                        //
                        // if (wpmDataLayer.cart[productId].isVariation) wpmDataLayer.cart[productId]['parentId_dyn_r_ids'] = wpmDataLayer.products[productId].parentId_dyn_r_ids;

                        wpmDataLayer.cart[productId] = wpm.getProductDetailsFormattedForEvent(productId, quantity);

                        if (sessionStorage) sessionStorage.setItem('wpmDataLayerCart', JSON.stringify(wpmDataLayer.cart));
                    }
                }
            }
        } catch (e) {
            console.log(e);

            // fallback if wpmDataLayer.cart and wpmDataLayer.products got out of sync in case cart caching has an issue
            wpm.getCartItemsFromBackend();
        }
    }

    wpm.getCartItems = function () {

        // console.log('get cart items');

        if (sessionStorage) {
            if (!sessionStorage.getItem('wpmDataLayerCart') || wpmDataLayer.shop.page_type === 'order_received_page') {
                sessionStorage.setItem('wpmDataLayerCart', JSON.stringify({}));
            } else {
                wpm.saveCartObjectToDataLayer(JSON.parse(sessionStorage.getItem('wpmDataLayerCart')));
            }
        } else {
            wpm.getCartItemsFromBackend();
        }
    }

    wpm.getCartItemsFromBackend = function () {
        // get all cart items from the backend
        try {
            let data = {
                action: 'wpm_get_cart_items',
            };

            jQuery.ajax(
                {
                    type    : 'get',
                    dataType: 'json',
                    // url     : ajax_object.ajax_url,
                    url     : wpm.ajax_url,
                    data    : data,
                    success : function (cartItems) {

                        // save all cart items into wpmDataLayer

                        if (!cartItems['cart']) cartItems['cart'] = {};

                        wpm.saveCartObjectToDataLayer(cartItems['cart']);
                        if (sessionStorage) sessionStorage.setItem('wpmDataLayerCart', JSON.stringify(cartItems['cart']));
                    }
                });
        } catch (e) {
            console.log(e);
        }
    }

    wpm.getProductsFromBackend = function (productIds) {
        // console.log('getting pids from back-end: ' + productIds)
        // get productIds from the backend

        // reduce productIds by products already in the dataLayer
        productIds = productIds.filter(item => !wpmDataLayer.products.hasOwnProperty(item));

        // if no products IDs are in the object, don't try to get anything from the server
        if (!productIds || productIds.length === 0) return;

        try {
            let data = {
                action    : 'wpm_get_product_ids',
                productIds: productIds,
            };

            jQuery.ajax(
                {
                    type    : 'get',
                    dataType: 'json',
                    // url     : ajax_object.ajax_url,
                    url     : wpm.ajax_url,
                    data    : data,
                    success : function (products) {

                        // merge products into wpmDataLayer.products
                        wpmDataLayer.products = Object.assign({}, wpmDataLayer.products, products);
                    },
                    error   : function (response) {
                        console.log(response);
                    }
                });
        } catch (e) {
            console.log(e);
        }
    }

    wpm.saveCartObjectToDataLayer = function (cartObject) {
        wpmDataLayer.cart     = cartObject;
        wpmDataLayer.products = Object.assign({}, wpmDataLayer.products, cartObject);
    }

    wpm.fireCheckoutOption = function (step, checkout_option = null, value = null) {

        let data = {
            step           : step,
            checkout_option: checkout_option,
            value          : value
        };

        jQuery(document).trigger('wpmFireCheckoutOption', data);
    }

    wpm.fireCheckoutProgress = function (step) {

        let data = {
            step: step,
        };

        jQuery(document).trigger('wpmFireCheckoutProgress', data);
    }

    wpm.getPostIdFromString = function (string) {
        // console.log(string);
        try {
            return string.match(/(post-)(\d+)/)[2];
        } catch (e) {
            console.log(e);
        }
    }

    wpm.triggerViewItemList = function (productId) {

        // productId = null;

        if (!productId) throw Error('Wasn\'t able to retrieve a productId');

        productId = getIdBasedOndVariationsOutputSetting(productId);

        if (!productId) throw Error('Wasn\'t able to retrieve a productId');

        jQuery(document).trigger('wpmViewItemList', wpm.getProductDataForViewItemEvent(productId));
    }

    wpm.getProductDataForViewItemEvent  = function (productId) {

        if (!productId) throw Error('Wasn\'t able to retrieve a productId');

        try {
            if (wpmDataLayer.products[productId]) {

                return wpm.getProductDetailsFormattedForEvent(productId);
            }
        } catch (e) {
            console.log(e);
        }
    }
    wpm.getMainProductIdFromProductPage = function () {
        try {
            if (['simple', 'variable', 'grouped', 'composite', 'bundle'].indexOf(wpmDataLayer.shop.product_type) >= 0) {
                return jQuery('.wpmProductId:first').data('id');
            } else {
                return false;
            }
        } catch (e) {
            console.log(e);
        }
    }

    wpm.viewItemListTriggerTestMode = function (target) {

        jQuery(target).css({"position": "relative"});
        jQuery(target).append('<div id="viewItemListTriggerOverlay"></div>')
        jQuery(target).find('#viewItemListTriggerOverlay').css({
            "z-index"         : "10",
            "display"         : "block",
            "position"        : "absolute",
            "height"          : "100%",
            "top"             : "0",
            "left"            : "0",
            "right"           : "0",
            "opacity"         : wpmDataLayer.viewItemListTrigger.opacity,
            "background-color": wpmDataLayer.viewItemListTrigger.backgroundColor,
        })
    }

    wpm.getSearchTermFromUrl = function () {
        try {
            let urlParameters = new URLSearchParams(window.location.search)
            return urlParameters.get('s');
        } catch (e) {
            console.log(e);
        }
    }

    // we need this to track timeouts for intersection observers
    let ioTimeouts = {};

    wpm.observerCallback = function (entries, observer) {

        entries.forEach((entry) => {

            try {
                let productId;

                let elementId = jQuery(entry.target).data('ioid');

                // Get the productId from next element, if wpmProductId is a sibling, like in Gutenberg blocks
                // otherwise go search in children, like in regular WC loop items
                if (jQuery(entry.target).next('.wpmProductId').length) {
                    // console.log('test 1');
                    productId = jQuery(entry.target).next('.wpmProductId').data('id');
                } else {
                    productId = jQuery(entry.target).find('.wpmProductId').data('id');
                }

                // productId = null;

                if (!productId) throw Error('wpmProductId element not found');

                if (entry.isIntersecting) {

                    // console.log('prodid: ' + productId);
                    ioTimeouts[elementId] = setTimeout(() => {
                        //                 console.log('prodid: ' + productId);
                        wpm.triggerViewItemList(productId);
                        if (wpmDataLayer.viewItemListTrigger.testMode) wpm.viewItemListTriggerTestMode(entry.target);
                        if (wpmDataLayer.viewItemListTrigger.repeat === false) observer.unobserve(entry.target);
                    }, wpmDataLayer.viewItemListTrigger.timeout)

                } else {

                    clearTimeout(ioTimeouts[elementId])
                    if (wpmDataLayer.viewItemListTrigger.testMode) jQuery(entry.target).find('#viewItemListTriggerOverlay').remove();
                }
            } catch (e) {
                console.log(e);
            }
        });
    }

    // fire view_item_list only on products that have become visible
    let io;
    let ioid = 0;
    let allIoElementsToWatch;

    let getAllElementsToWatch = function () {

        allIoElementsToWatch = jQuery('.wpmProductId')
            .map(function (i, elem) {
                // console.log(elem);
                if (
                    jQuery(elem).parent().hasClass('type-product') ||
                    jQuery(elem).parent().hasClass('product') ||
                    jQuery(elem).parent().hasClass('product-item-inner')
                ) {
                    // console.log(elem);
                    return jQuery(elem).parent();
                } else if (
                    jQuery(elem).prev().hasClass('wc-block-grid__product') ||
                    jQuery(elem).prev().hasClass('product') ||
                    jQuery(elem).prev().hasClass('product-small') ||
                    jQuery(elem).prev().hasClass('woocommerce-LoopProduct-link')
                ) {
                    return jQuery(this).prev();
                } else if (jQuery(elem).closest('.product').length) {
                    return jQuery(elem).closest('.product');
                }
            });
    }

    wpm.startIntersectionObserverToWatch = function () {

        try {
            // enable view_item_list test mode from browser
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('vildemomode')) wpmDataLayer.viewItemListTrigger.testMode = true;

            // set up intersection observer
            io = new IntersectionObserver(wpm.observerCallback, {
                threshold: wpmDataLayer.viewItemListTrigger.threshold,
            });

            getAllElementsToWatch();

            // console.log(allElementsToWatch);

            allIoElementsToWatch.each(function (i, elem) {
                // console.log(elem[0]);
                // jQuery(elem[0]).attr('data-ioid', ioid++);
                jQuery(elem[0]).data('ioid', ioid++);

                io.observe(elem[0])
            });
        } catch (e) {
            console.log(e);
        }
    }

    // watch DOM for new lazy loaded products and add them to the intersection observer
    wpm.startProductsMutationObserverToWatch = function () {

        try {
            // Pass in the target node, as well as the observer options

            // selects the most common parent node
            // https://stackoverflow.com/a/7648323/4688612
            let productsNode = jQuery('.wpmProductId:eq(0)').parents().has(jQuery('.wpmProductId:eq(1)').parents()).first()

            if (productsNode.length) {
                productsMutationObserver.observe(productsNode[0], {
                    attributes   : true,
                    childList    : true,
                    characterData: true
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    // Create an observer instance
    let productsMutationObserver = new MutationObserver(function (mutations) {

        mutations.forEach(function (mutation) {
            let newNodes = mutation.addedNodes; // DOM NodeList
            if (newNodes !== null) { // If there are new nodes added
                let nodes = jQuery(newNodes); // jQuery set
                nodes.each(function () {
                    if (
                        jQuery(this).hasClass("type-product") ||
                        jQuery(this).hasClass("product-small") ||
                        jQuery(this).hasClass("wc-block-grid__product")
                    ) {
                        // check if the node has a child or sibling wpmProductId
                        // if yes add it to the intersectionObserver
                        if (hasWpmProductIdElement(this)) {
                            jQuery(this).data('ioid', ioid++);
                            io.observe(this)
                        }
                    }
                });
            }
        });
    });

    let hasWpmProductIdElement = function (elem) {
        return !!(jQuery(elem).find('.wpmProductId').length ||
            jQuery(elem).siblings('.wpmProductId').length);
    }

    wpm.setCookie = function (cookieName, cookieValue = '', expiryDays = null) {
        if (expiryDays) {
            let d = new Date();
            d.setTime(d.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
            let expires     = "expires=" + d.toUTCString();
            document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
        } else {
            document.cookie = cookieName + "=" + cookieValue + ";path=/";
        }
    }

    wpm.getCookie = function (cookieName) {
        let name          = cookieName + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca            = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    wpm.storeOrderIdOnServer = function (orderId) {

        // console.log('saving wpm_purchase_pixels_fired');

        // console.log('url: ' + wpm_premium_only_ajax_object.ajax_url);
        // console.log('nonce: ' + wpm_premium_only_ajax_object.nonce);
        // console.log('url: ' + wpm.ajax_url);
        // console.log('nonce: ' + wpm.nonce);

        try {
            // save the state in the database
            let data = {
                action  : 'wpm_purchase_pixels_fired',
                order_id: orderId,
                // nonce   : ajax_object.nonce,
                nonce   : wpm.nonce,
            };

            jQuery.ajax(
                {
                    type    : 'post',
                    dataType: 'json',
                    // url     : ajax_object.ajax_url,
                    url     : wpm.ajax_url,
                    data    : data,
                    success : function (response) {
                        if (response.success === false) {
                            console.log(response);
                        }
                    },
                    error   : function (response) {
                        console.log(response);
                    }
                });
        } catch (e) {
            console.log(e);
        }
    }

    wpm.getProductIdByCartItemKeyUrl = function (url) {
        let searchParams = new URLSearchParams(url.search);
        let cartItemKey  = searchParams.get('remove_item');

        let productId = null;

        if (wpmDataLayer.cartItemKeys[cartItemKey]['variation_id'] === 0) {
            productId = wpmDataLayer.cartItemKeys[cartItemKey]['product_id'];
        } else {
            productId = wpmDataLayer.cartItemKeys[cartItemKey]['variation_id'];
        }

        return productId;
    }

    wpm.getAddToCartLinkProductIds = function () {
        return jQuery('a').map(function () {
            let href = jQuery(this).attr('href');

            if (href && href.includes('?add-to-cart=')) {
                let matches = href.match(/(add-to-cart=)(\d+)/);
                if (matches) return matches[2];
            }
        }).get()
    }

    wpm.getProductDetailsFormattedForEvent = function (productId, quantity = 1) {

        let product = {
            id           : productId.toString(),
            dyn_r_ids    : wpmDataLayer.products[productId].dyn_r_ids,
            name         : wpmDataLayer.products[productId].name,
            list_name    : wpmDataLayer.shop.list_name,
            brand        : wpmDataLayer.products[productId].brand,
            category     : wpmDataLayer.products[productId].category,
            variant      : wpmDataLayer.products[productId].variant,
            list_position: wpmDataLayer.products[productId].position,
            quantity     : quantity,
            price        : wpmDataLayer.products[productId].price,
            currency     : wpmDataLayer.shop.currency,
            isVariable   : wpmDataLayer.products[productId].isVariable,
            isVariation  : wpmDataLayer.products[productId].isVariation,
            parentId     : wpmDataLayer.products[productId].parentId,
        };

        if (product.isVariation) product['parentId_dyn_r_ids'] = wpmDataLayer.products[productId].parentId_dyn_r_ids;

        return product;
    }

    wpm.setReferrerToCookie = function () {
        // can't use session storage as we can't read it from the server
        if (!wpm.getCookie('wpmReferrer')) {
            wpm.setCookie('wpmReferrer', document.referrer);
        }
    }

    wpm.getReferrerFromCookie = function () {
        if (wpm.getCookie('wpmReferrer')) {
            return wpm.getCookie('wpmReferrer');
        } else {
            return null;
        }
    }

    wpm.getClidFromBrowser = function (clidId = 'gclid') {

        let clidCookieId;

        clidCookieId = {
            gclid: '_gcl_aw',
            dclid: '_gcl_dc',
        };

        if (wpm.getCookie(clidCookieId[clidId])) {
            let clidCookie = wpm.getCookie(clidCookieId[clidId]);
            let matches    = clidCookie.match(/(GCL.[\d]*.)(.*)/);
            return matches[2];
        } else {
            return '';
        }
    }

    wpm.getUserAgent = function () {
        return navigator.userAgent;
    }

    wpm.getViewPort = function () {
        return {
            width : Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
            height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
        };
    }

    /*
    * Handle Cookie Management Platforms
    * */

    let getComplianzCookies = function () {

        let cmplz_statistics     = wpm.getCookie('cmplz_statistics')
        let cmplz_marketing      = wpm.getCookie('cmplz_marketing')
        let cmplz_consent_status = wpm.getCookie('cmplz_consent_status') || wpm.getCookie('cmplz_banner-status')


        if (cmplz_consent_status) {
            return {
                analytics       : cmplz_statistics === 'allow',
                ads             : cmplz_marketing === 'allow',
                visitorHasChosen: true
            }
        } else {
            return false
        }
    }

    let getCookieLawInfoCookies = function () {

        let analyticsCookie  = wpm.getCookie('cookielawinfo-checkbox-analytics')
        let adsCookie        = wpm.getCookie('cookielawinfo-checkbox-advertisement')
        let visitorHasChosen = wpm.getCookie('CookieLawInfoConsent')

        if (analyticsCookie || adsCookie) {

            return {
                analytics       : analyticsCookie === 'yes',
                ads             : adsCookie === 'yes',
                visitorHasChosen: !!visitorHasChosen,
            }
        } else {
            return false
        }
    }


    let
        wpmConsentValues              = {}
    wpmConsentValues.categories       = {}
    wpmConsentValues.pixels           = []
    wpmConsentValues.mode             = 'category'
    wpmConsentValues.visitorHasChosen = false

    wpm.getConsentValues = function () {
        return wpmConsentValues
    }

    wpm.updateConsentCookieValues = function (explicitConsent = false) {

        // ad_storage
        // analytics_storage
        // functionality_storage
        // personalization_storage
        // security_storage

        // console.log('exp cons: ' + explicitConsent)

        let cookie

        if (cookie = wpm.getCookie('CookieConsent')) {
            // Cookiebot
            // https://wordpress.org/plugins/cookiebot/
            cookie = decodeURI(cookie)

            // console.log(cookie.indexOf('statistics:true'))
            // console.log(cookie.indexOf('marketing:true'))

            wpmConsentValues.categories.analytics = cookie.indexOf('statistics:true') >= 0
            wpmConsentValues.categories.ads       = cookie.indexOf('marketing:true') >= 0
            wpmConsentValues.visitorHasChosen     = true

        } else if (cookie = wpm.getCookie('CookieScriptConsent')) {
            // Cookie Script
            // https://wordpress.org/plugins/cookie-script-com/

            cookie = JSON.parse(cookie)

            // console.log(cookie)
            // console.log(cookie.action)
            // console.log('cat length: ' + cookie.categories.length)
            // console.log(cookie.categories.indexOf('performance'))

            if (cookie.action === 'reject') {
                wpmConsentValues.categories.analytics = false
                wpmConsentValues.categories.ads       = false
            } else if (cookie.categories.length === 2) {
                wpmConsentValues.categories.analytics = true
                wpmConsentValues.categories.ads       = true
            } else {
                wpmConsentValues.categories.analytics = cookie.categories.indexOf('performance') >= 0
                wpmConsentValues.categories.ads       = cookie.categories.indexOf('targeting') >= 0
            }

            wpmConsentValues.visitorHasChosen = true

            // console.log(wpmConsentValues)

        } else if (cookie = wpm.getCookie('borlabs-cookie')) {
            // Borlabs Cookie
            // https://borlabs.io/borlabs-cookie/

            cookie = decodeURI(cookie)
            cookie = JSON.parse(cookie)

            wpmConsentValues.categories.analytics = !!cookie?.consents?.statistics
            wpmConsentValues.categories.ads       = !!cookie?.consents?.marketing
            wpmConsentValues.visitorHasChosen     = true
            wpmConsentValues.pixels               = [...cookie?.consents?.statistics || [], ...cookie?.consents?.marketing || []]
            wpmConsentValues.mode                 = 'pixel'

        } else if (cookie = getComplianzCookies()) {
            // Complianz Cookie
            // https://wordpress.org/plugins/complianz-gdpr/

            console.log(cookie)

            wpmConsentValues.categories.analytics = cookie.analytics === true
            wpmConsentValues.categories.ads       = cookie.ads === true
            wpmConsentValues.visitorHasChosen     = cookie.visitorHasChosen

            // console.log(wpmConsentValues)

        } else if (cookie = wpm.getCookie('cookie_notice_accepted')) {
            // Cookie Compliance (free version)
            // https://wordpress.org/plugins/cookie-notice/

            wpmConsentValues.categories.analytics = true
            wpmConsentValues.categories.ads       = true
            wpmConsentValues.visitorHasChosen     = true

        } else if (cookie = wpm.getCookie('hu-consent')) {
            // Cookie Compliance (pro version)
            // https://wordpress.org/plugins/cookie-notice/

            cookie = JSON.parse(cookie)

            wpmConsentValues.categories.analytics = !!cookie.categories['3']
            wpmConsentValues.categories.ads       = !!cookie.categories['4']
            wpmConsentValues.visitorHasChosen     = true

        } else if (cookie = getCookieLawInfoCookies()) {
            // CookieYes, GDPR Cookie Consent (Cookie Law Info)
            // https://wordpress.org/plugins/cookie-law-info/

            wpmConsentValues.categories.analytics = cookie.analytics === true
            wpmConsentValues.categories.ads       = cookie.ads === true
            wpmConsentValues.visitorHasChosen     = cookie.visitorHasChosen === true

        } else if (cookie = wpm.getCookie('moove_gdpr_popup')) {
            // GDPR Cookie Compliance Plugin by Moove Agency
            // https://wordpress.org/plugins/gdpr-cookie-compliance/
            // TODO write documentation on how to set up the plugin in order for this to work properly

            cookie = JSON.parse(cookie)
            // console.log('moove cookie thirdparty: ' + cookie.thirdparty)
            // console.log('moove cookie advanced: ' + cookie.advanced)

            wpmConsentValues.categories.analytics = cookie.thirdparty === '1'
            wpmConsentValues.categories.ads       = cookie.advanced === '1'
            wpmConsentValues.visitorHasChosen     = true

            // console.log(wpmConsentValues)

        } else {
            // consentValues.categories.analytics = true
            // consentValues.categories.ads       = true

            wpmConsentValues.categories.analytics = !explicitConsent
            wpmConsentValues.categories.ads       = !explicitConsent
        }
    }

    wpm.updateConsentCookieValues();

    wpm.setConsentDefaultValuesToExplicit = function () {
        wpmConsentValues.categories = {
            analytics: false,
            ads      : false,
        }
    }

    /*
    * Runs through each script in <head> and blocks / unblocks it according to the plugin settings
    * and user consent.
    */

    // https://stackoverflow.com/q/65453565/4688612
    wpm.scriptTagObserver = new MutationObserver((mutations) => {
        mutations.forEach(({addedNodes}) => {
            [...addedNodes]
                .forEach(node => {

                    if ($(node).data('wpm-cookie-category')) {

                        // console.log(node)

                        // If the pixel category has been approved > unblock
                        // If the pixel belongs to more than one category, then unblock if one of the categories has been approved
                        // If no category has been approved, but the Google Consent Mode is active, then only unblock the Google scripts

                        if (wpm.shouldScriptBeActive(node)) {
                            wpm.unblockScript(node)
                        } else {
                            wpm.blockScript(node)
                        }
                    }
                });
        });
    })

    wpm.scriptTagObserver.observe(document.head, {childList: true, subtree: true});
    window.addEventListener('DOMContentLoaded', () => wpm.scriptTagObserver.disconnect());

    wpm.shouldScriptBeActive = function (node) {

        if (
            wpmDataLayer.shop.cookie_consent_mgmt.explicit_consent ||
            wpmConsentValues.visitorHasChosen
        ) {

            if (wpmConsentValues.mode === 'category' && $(node).data('wpm-cookie-category').split(',').some(element => wpmConsentValues.categories[element])) {
                return true
            } else if (wpmConsentValues.mode === 'pixel' && wpmConsentValues.pixels.includes($(node).data('wpm-pixel-name'))) {
                return true
            } else if (wpmConsentValues.mode === 'pixel' && $(node).data('wpm-pixel-name') === 'google' && ['google-analytics', 'google-ads'].some(element => wpmConsentValues.pixels.includes(element))) {
                return true
            } else if (wpmDataLayer?.pixels?.google?.consent_mode?.active && $(node).data('wpm-pixel-name') === 'google') {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }


    wpm.unblockScript = function (scriptNode, removeAttach = false) {

        if (removeAttach) $(scriptNode).remove()

        let wpmSrc = $(scriptNode).data('wpm-src')
        if (wpmSrc) $(scriptNode).attr('src', wpmSrc)

        scriptNode.type = 'text/javascript'

        if (removeAttach) $(scriptNode).appendTo('head')
    }

    wpm.blockScript = function (scriptNode, removeAttach = false) {

        if (removeAttach) $(scriptNode).remove()

        if ($(scriptNode).attr('src')) $(scriptNode).removeAttr('src')
        scriptNode.type = 'blocked/javascript'

        if (removeAttach) $(scriptNode).appendTo('head')
    }

    wpm.unblockAllScripts = function (analytics = true, ads = true) {
        // console.log('unblocking all scripts')

        $.each(
            $('script[type="blocked/javascript"]'), function (index, scriptNode) {

                if ($(scriptNode).data('wpm-cookie-category').includes('analytics') && analytics) {
                    wpm.unblockScript(scriptNode, true)
                } else if ($(scriptNode).data('wpm-cookie-category').includes('ads') && ads) {
                    wpm.unblockScript(scriptNode, true)
                }
            });
    }

    wpm.unblockSelectedPixels = function () {
        $.each(
            $('script[type="blocked/javascript"]'), function (index, node) {

                if (wpmConsentValues.pixels.includes($(node).data('wpm-pixel-name'))) {
                    wpm.unblockScript(node, true)
                } else if ($(node).data('wpm-pixel-name') === 'google' && ['google-analytics', 'google-ads'].some(element => wpmConsentValues.pixels.includes(element))) {
                    wpm.unblockScript(node, true)
                }
            });
    }

    wpm.googleConfigConditionsMet = function (type) {
        // always returns true if Google Consent Mode is active
        if (wpmDataLayer.pixels.google.consent_mode.active) {
            return true
        } else if (wpmConsentValues.mode === 'category') {
            return wpmConsentValues.categories[type] === true
        } else if (wpmConsentValues.mode === 'pixel') {
            return wpmConsentValues.pixels.includes('google-' + type)
        } else {
            return false
        }
    }

    wpm.getVisitorConsentStatusAndUpdateGoogleConsentSettings = function (google_consent_settings) {

        if (wpmConsentValues.mode === 'category') {
            if (wpmConsentValues.categories.analytics) google_consent_settings.analytics_storage = 'granted'
            if (wpmConsentValues.categories.ads) google_consent_settings.ad_storage = 'granted'
        } else if ((wpmConsentValues.mode === 'pixel')) {
            google_consent_settings.analytics_storage = wpmConsentValues.pixels.includes('google-analytics') ? 'granted' : 'denied'
            google_consent_settings.ad_storage        = wpmConsentValues.pixels.includes('google-ads') ? 'granted' : 'denied'
        }

        return google_consent_settings
    }

    wpm.updateGoogleConsentMode = function (analytics = true, ads = true) {

        if (
            !window.gtag ||
            !wpmDataLayer.shop.cookie_consent_mgmt.explicit_consent
        ) return

        gtag('consent', 'update', {
            'analytics_storage': analytics ? 'granted' : 'denied',
            'ad_storage'       : ads ? 'granted' : 'denied'
        });
    }


    /*
    * Block or unblock scripts for each CMP immediately after cookie consent has been updated
    * by the visitor.
     */

    // Borlabs Cookie
    // If visitor accepts cookies in Borlabs Cookie unblock the scripts
    window.addEventListener('borlabs-cookie-consent-saved', function (e) {

        wpm.updateConsentCookieValues();

        if (wpmConsentValues.mode === 'pixel') {

            wpm.unblockSelectedPixels()
            wpm.updateGoogleConsentMode(wpmConsentValues.pixels.includes('google-analytics'), wpmConsentValues.pixels.includes('google-ads'))
        } else {

            wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
            wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
        }
    });

    // Cookiebot
    // If visitor accepts cookies in Cookiebot unblock the scripts
    // https://www.cookiebot.com/en/developer/
    window.addEventListener('CookiebotOnAccept', function (e) {
        if (Cookiebot.consent.statistics) wpmConsentValues.categories.analytics = true
        if (Cookiebot.consent.marketing) wpmConsentValues.categories.ads = true

        wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
        wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)

    }, false);

    // Cookie Script
    // If visitor accepts cookies in Cookie Script unblock the scripts
    // https://support.cookie-script.com/article/20-custom-events
    window.addEventListener('CookieScriptAccept', function (e) {
        if (e.detail.categories.includes('performance')) wpmConsentValues.categories.analytics = true
        if (e.detail.categories.includes('targeting')) wpmConsentValues.categories.ads = true

        wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
        wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
    })

    // Cookie Script
    // If visitor accepts cookies in Cookie Script unblock the scripts
    // https://support.cookie-script.com/
    window.addEventListener('CookieScriptAcceptAll', function (e) {
        wpm.unblockAllScripts(true, true)
        wpm.updateGoogleConsentMode(true, true)
    })

    // Complianz Cookie
    // If visitor accepts cookies in Complianz unblock the scripts
    document.addEventListener("cmplzStatusChange", function (e) {

        wpm.updateConsentCookieValues();

        // console.log(wpmConsentValues)

        wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
        wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
    });

    // Cookie Compliance by hu-manity.co (free and pro)
    // If visitor accepts cookies in Cookie Notice by hu-manity.co unblock the scripts (free version)
    // https://wordpress.org/support/topic/events-on-consent-change/#post-15202792
    document.addEventListener("setCookieNotice", function (e) {

        wpm.updateConsentCookieValues();

        // console.log(wpmConsentValues)

        wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
        wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
    });

    // Cookie Compliance by hu-manity.co (free and pro)
    // If visitor accepts cookies in Cookie Notice by hu-manity.co unblock the scripts (pro version)
    // https://wordpress.org/support/topic/events-on-consent-change/#post-15202792
    // Because Cookie Notice has no documented API or event that is being triggered on consent save or update
    // we have to solve this by using a mutation observer.
    wpm.huObserver = new MutationObserver(function (mutations) {
        mutations.forEach(({addedNodes}) => {
            [...addedNodes]
                .forEach(node => {

                    if (node.id === 'hu') {

                        jQuery('.hu-cookies-save').on('click', function (e) {
                            wpm.updateConsentCookieValues();
                            wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
                            wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
                        });
                    }
                });
        });
    });

    if (window.hu) {
        wpm.huObserver.observe(document.documentElement || document.body, {childList: true, subtree: true});
    }

    wpm.version = function (){
        console.log(wpmDataLayer.version)
    }

    // wpm['load'] = {
    //     base: true
    // };

    window['wpmLoaded'] = {};

    // return {
    // writeOrderIdToStorage  : writeOrderIdToStorage,
    // isOrderIdStored        : isOrderIdStored,
    // isEmail                : isEmail,
    // removeProductFromCart  : removeProductFromCart,
    // getViewItemProducts    : getViewItemProducts,
    // addProductToCart       : addProductToCart,
    // getCartItemsFromBackEnd: getCartItemsFromBackEnd,
    // fireCheckoutOption     : fireCheckoutOption,
    // getCartItems           : getCartItems
    // }

}(window.wpm = window.wpm || {}, jQuery));

// run when window ready
jQuery(function () {
// jQuery(window).on('load', function () {

    // watch for products visible in viewport
    wpm.startIntersectionObserverToWatch();

    // watch for lazy loaded products
    wpm.startProductsMutationObserverToWatch();

    let body     = jQuery('body');
    let products = jQuery('.products, .product');

    // remove_from_cart event
    // body.on('click', '.remove_from_cart_button, .remove', function (e) {
    jQuery(document).on('click', '.remove_from_cart_button, .remove', function (e) {
        // jQuery('.remove_from_cart_button, .remove').on('click', function (e) {

        try {
            // console.log('remove_from_cart: ' + jQuery(this).data('product_id'));

            let url       = new URL(jQuery(this).attr('href'));
            let productId = wpm.getProductIdByCartItemKeyUrl(url);

            wpm.removeProductFromCart(productId);

        } catch (e) {
            console.log(e);
        }
    });


    // add_to_cart event
    // body.on('click', '.add_to_cart_button:not(.product_type_variable), .ajax_add_to_cart, .single_add_to_cart_button', function (e) {
    jQuery(document).on('click', '.add_to_cart_button:not(.product_type_variable), .ajax_add_to_cart, .single_add_to_cart_button', function (e) {
        // jQuery('.add_to_cart_button:not(.product_type_variable), .ajax_add_to_cart, .js-ajax-add-to-cart, .single_add_to_cart_button, .btn_color-2').on('click', function (e) {

        try {
            // console.log('add_to_cart');

            if (wpmDataLayer.shop.page_type === 'product') {

                // first process related and upsell products
                if (typeof jQuery(this).attr('href') !== 'undefined' && jQuery(this).attr('href').includes('add-to-cart')) {
                    // console.log('add-to-cart on upsell and related products');
                    let quantity  = 1;
                    let productId = jQuery(this).data('product_id');
                    // console.log('productId: ' + productId);
                    wpm.addProductToCart(productId, quantity);
                } else {

                    if (wpmDataLayer.shop.product_type === 'simple') {

                        // console.log('test');
                        let quantity = Number(jQuery('.input-text.qty').val());

                        if (!quantity && quantity !== 0) quantity = 1;

                        let productId = jQuery(this).val();

                        // console.log('productId: ' + productId);
                        // console.log('quantity: ' + quantity);

                        wpm.addProductToCart(productId, quantity);

                    } else if (wpmDataLayer.shop.product_type === 'variable') {

                        // console.log('variable');

                        let quantity = Number(jQuery('.input-text.qty').val());

                        if (!quantity && quantity !== 0) quantity = 1;

                        let productId = jQuery("[name='variation_id']").val();

                        // console.log('productId: ' + productId);
                        // console.log('quantity: ' + quantity);

                        wpm.addProductToCart(productId, quantity);

                    } else if (wpmDataLayer.shop.product_type === 'grouped') {

                        // console.log('grouped');

                        jQuery('.woocommerce-grouped-product-list-item').each(function () {

                            let quantity = Number(jQuery(this).find('.input-text.qty').val());

                            if (!quantity && quantity !== 0) quantity = 1;

                            let classes   = jQuery(this).attr('class');
                            let productId = wpm.getPostIdFromString(classes);

                            // console.log('productId: ' + productId);
                            // console.log('quantity: ' + quantity);

                            wpm.addProductToCart(productId, quantity);
                        });
                    } else if (wpmDataLayer.shop.product_type === 'bundle') {

                        // console.log('bundle');

                        let quantity = Number(jQuery('.input-text.qty').val());

                        if (!quantity && quantity !== 0) quantity = 1;

                        let productId = jQuery('input[name=add-to-cart]').val();

                        // console.log('productId: ' + productId);
                        // console.log('quantity: ' + quantity);

                        wpm.addProductToCart(productId, quantity);
                    }
                }
            } else {

                // console.log('non product page');

                let quantity  = 1;
                let productId = jQuery(this).data('product_id');
                // console.log('productId: ' + productId);
                wpm.addProductToCart(productId, quantity);
            }
        } catch (e) {
            console.log(e);
        }
    });

    // if someone clicks anywhere on a custom /?add-to-cart=123 link
    // trigger the add to cart event
    // body.one('click', function (e) {
    //
    //     try {
    //         if (jQuery(this)[0].URL) {
    //
    //             let href         = new URL(jQuery(this)[0].URL);
    //             let searchParams = new URLSearchParams(href.search);
    //
    //             if (searchParams.has('add-to-cart')) {
    //                 // console.log('non product page, /?add-to-cart=123 link');
    //
    //                 let productId = searchParams.get('add-to-cart');
    //                 wpm.addProductToCart(productId, 1);
    //             }
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // });

    // if someone clicks anywhere on a custom /?add-to-cart=123 link
    // trigger the add to cart event
    // body.one('click', 'a:not(.add_to_cart_button, .ajax_add_to_cart, .single_add_to_cart_button)', function (event) {
    jQuery(document).one('click', 'a:not(.add_to_cart_button, .ajax_add_to_cart, .single_add_to_cart_button)', function (event) {
        // jQuery('a:not(.add_to_cart_button, .ajax_add_to_cart, .single_add_to_cart_button)').one('click', function (event) {

        try {
            if (jQuery(event.target).closest('a').attr('href')) {

                let href = jQuery(event.target).closest('a').attr('href');

                if (href.includes('add-to-cart=')) {
                    let matches = href.match(/(add-to-cart=)(\d+)/);
                    // console.log('pid: ' + matches[2])
                    if (matches) wpm.addProductToCart(matches[2], 1);
                }
            }
        } catch (e) {
            console.log(e);
        }
    });

    // select_content GA UA event
    // select_item GA 4 event
    // jQuery(document).on('click', '.woocommerce-LoopProduct-link, .wc-block-grid__product, .product-small.box', function (e) {
    // body.on('click', '.woocommerce-LoopProduct-link, .wc-block-grid__product, .product, .product-small, .type-product', function (e) {
    jQuery(document).on('click', '.woocommerce-LoopProduct-link, .wc-block-grid__product, .product, .product-small, .type-product', function (e) {
        // jQuery('.woocommerce-LoopProduct-link, .wc-block-grid__product, .product, .product-small, .type-product').on('click', function (e) {

        try {

            // On some pages the event fires multiple times, and on product pages
            // even on page load. Using e.stopPropagation helps to prevent this,
            // but I dont know why. We don't even have to use this, since only a real
            // product click yields a valid productId. So we filter the invalid click
            // events out later down the code. I'll keep it that way because this is
            // the most compatible way across shops.
            // e.stopPropagation();

            // console.log('select_content and select_item');

            let productId = jQuery(this).nextAll('.wpmProductId:first').data('id');
            // console.log('select_content and select_item: ' + productId);


            // On product pages, for some reason, the click event is triggered on the main product on page load.
            // In that case no ID is found. But we can discard it, since we only want to trigger the event on
            // related products, which are found below.
            if (productId) {

                // console.log('select_content and select_item: ' + productId);

                productId = getIdBasedOndVariationsOutputSetting(productId);

                if (!productId) throw Error('Wasn\'t able to retrieve a productId');

                // console.log('prodid: ' + productId);

                if (wpmDataLayer.products && wpmDataLayer.products[productId]) {

                    let product = wpm.getProductDetailsFormattedForEvent(productId);

                    jQuery(document).trigger('wpmSelectContentGaUa', product);
                    jQuery(document).trigger('wpmSelectItem', product);
                }
            }
        } catch (e) {
            console.log(e);
        }
    });

    // begin_checkout event
    // body.one('click', '.checkout-button, .cart-checkout-button, .button.checkout', function (e) {
    jQuery(document).one('click', '.checkout-button, .cart-checkout-button, .button.checkout', function (e) {
        // jQuery('.checkout-button, .cart-checkout-button, .button.checkout').one('click', function (e) {
        // console.log('begin_checkout');

        jQuery(document).trigger('wpmBeginCheckout');
    });

    let emailSelected = false;

    // checkout_progress event
    // track checkout option event: entered valid billing email
    // body.on('input', '#billing_email', function () {
    jQuery(document).on('input', '#billing_email', function () {
        // jQuery('#billing_email').on('input',  function () {

        if (wpm.isEmail(jQuery(this).val())) {
            // wpm.fireCheckoutOption(2);
            wpm.fireCheckoutProgress(2);
            emailSelected = true;
        }
    });

    // track checkout option event: purchase click
    let paymentMethodSelected = false;

    // body.on('click', '.wc_payment_methods', function () {
    jQuery(document).on('click', '.wc_payment_methods', function () {
        // jQuery('.wc_payment_methods').on('click', function () {

        if (paymentMethodSelected === false) {
            wpm.fireCheckoutProgress(3);
        }

        wpm.fireCheckoutOption(3, jQuery("input[name='payment_method']:checked").val());
        paymentMethodSelected = true;
    });

    // track checkout option event: purchase click
    // body.one('click', '#place_order', function () {
    jQuery(document).one('click', '#place_order', function () {
        // jQuery('#place_order').one('click',  function () {

        if (emailSelected === false) {
            wpm.fireCheckoutProgress(2);
        }

        if (paymentMethodSelected === false) {
            wpm.fireCheckoutProgress(3);
            wpm.fireCheckoutOption(3, jQuery("input[name='payment_method']:checked").val());
        }

        wpm.fireCheckoutProgress(4);
    });

    // update cart event
    // body.on('click', "[name='update_cart']", function (e) {
    jQuery(document).on('click', "[name='update_cart']", function (e) {
        //     jQuery("[name='update_cart']").on('click',  function (e) {

        try {
            jQuery('.cart_item').each(function () {

                // let productId = jQuery(this).find('[data-product_id]').data('product_id');

                let url       = new URL(jQuery(this).find('.product-remove').find('a').attr('href'));
                let productId = wpm.getProductIdByCartItemKeyUrl(url);

                // console.log(productId)

                let quantity = jQuery(this).find('.qty').val();

                if (quantity === 0) {
                    wpm.removeProductFromCart(productId);
                } else if (quantity < wpmDataLayer.cart[productId].quantity) {
                    wpm.removeProductFromCart(productId, wpmDataLayer.cart[productId].quantity - quantity);
                } else if (quantity > wpmDataLayer.cart[productId].quantity) {
                    wpm.addProductToCart(productId, quantity - wpmDataLayer.cart[productId].quantity);
                }
            });
        } catch (e) {
            console.log(e);
            wpm.getCartItemsFromBackend();
        }
    });

    // Fired when the user selects all the required dropdowns / attributes
    // https://stackoverflow.com/a/27849208/4688612
    jQuery(".single_variation_wrap").on('show_variation', function (event, variation) {

        try {
            // Fired when the user selects all the required dropdowns / attributes
            // console.log('product selected');
            // console.log(variation);

            let productId = getIdBasedOndVariationsOutputSetting(variation.variation_id);

            if (!productId) throw Error('Wasn\'t able to retrieve a productId');

            if (wpmDataLayer.products && wpmDataLayer.products[productId]) {

                // console.log('productId: ' + productId);

                let product = wpm.getProductDetailsFormattedForEvent(productId);

                jQuery(document).trigger('wpmViewItem', product);
            }
        } catch (e) {
            console.log(e);
        }
    });

    // add_to_wishlist
    // body.on('click', '.add_to_wishlist, .wl-add-to', function () {
    jQuery(document).on('click', '.add_to_wishlist, .wl-add-to', function () {
        // jQuery('.add_to_wishlist, .wl-add-to').on('click',  function () {

        try {
            // console.log('add_to_wishlist');
            // console.log('this:' + jQuery(this).data('product-id'));

            let productId;

            if (jQuery(this).data('productid')) { // for the WooCommerce wishlist plugin
                productId = jQuery(this).data('productid');
            } else if (jQuery(this).data('product-id')) {  // for the YITH wishlist plugin
                productId = jQuery(this).data('product-id');
            }

            if (!productId) throw Error('Wasn\'t able to retrieve a productId');

            let product = wpm.getProductDetailsFormattedForEvent(productId);

            // console.log('add_to_wishlist');
            // console.log(product);

            jQuery(document).trigger('wpmAddToWishlist', product);
        } catch (e) {
            console.log(e);
        }
    })

    // body.on('updated_cart_totals', function () {
    jQuery(document).on('updated_cart_totals', function () {

        jQuery(document).trigger('wpmViewCart');
    });
});

jQuery(document).ajaxSend(function (event, jqxhr, settings) {
    // console.log('settings.url: ' + settings.url);

    if (settings.url.includes('get_refreshed_fragments') && sessionStorage) {
        if (!sessionStorage.getItem('wpmMiniCartActive')) {
            sessionStorage.setItem('wpmMiniCartActive', JSON.stringify(true));
        }
    }
});

// populate the wpmDataLayer with the cart items
jQuery(window).on('load', function () {
    // console.log('getting cart');

    try {
        // console.log('wpmMiniCartActive: ' + JSON.parse(sessionStorage.getItem('wpmMiniCartActive')));
        // if ( wpmDataLayer.shop.page_type === 'cart' || wpmDataLayer.shop.mini_cart.track === true) {

        if (
            JSON.parse(sessionStorage.getItem('wpmMiniCartActive')) && // if we detected calls to get_refreshed_fragments
            JSON.parse(sessionStorage.getItem('wpmFirstPageLoad')) &&  // when a new session is initiated there are no items in the cart, so we can save that call
            wpmDataLayer.shop.mini_cart.track === true                      // if shop owner generally allows the plugin to track the mini cart
        ) {
            // console.log('getting cart');
            wpm.getCartItems();

        } else {
            sessionStorage.setItem('wpmFirstPageLoad', JSON.stringify(true));
        }
    } catch (e) {
        console.log(e);
    }
});

// get all add-to-cart= products from backend
jQuery(window).on('load', function () {

    wpmDataLayer.products = wpmDataLayer.products || {};
    // wpmDataLayer.products = {14: 'x', 31: 'x', 16: 'x', 22: 'x', 40: 'x'};

    // scan page for add-to-cart= links
    let productIds = wpm.getAddToCartLinkProductIds();

    wpm.getProductsFromBackend(productIds);
})

jQuery(window).on('load', function () {

    // can't use session storage as we can't read it from the server
    if (!wpm.getCookie('wpmReferrer')) {

        if (document.referrer) {
            let referrerUrl      = new URL(document.referrer);
            let referrerHostname = referrerUrl.hostname;

            if (referrerHostname !== window.location.host) {
                wpm.setCookie('wpmReferrer', referrerHostname);
            } else {
                wpm.setCookie('wpmReferrer', '');
            }

        } else {
            wpm.setCookie('wpmReferrer', '');
        }
    }
})